<!-- LoadingComponent.vue -->
<template>
  <div v-if="isLoading" class="loading-overlay">
    <!-- 这里是你的loading样式，可以是一个简单的加载图标或者进度条 -->
    Loading...
  </div>
</template>
 
<script>
export default {
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    show() {
      this.isLoading = true
    },
    hide() {
      this.isLoading = false
    }
  }
}
</script>
 
<style>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}
</style>