import _axios from '@/api/axios'

export default {
    //详情页
    goodsDetail(data) {
        return _axios({
            url: '/huarenApi/pc/product/detail',
            method: 'post',
            data
        })
    },
    //收藏
    collect(data) {
        return _axios({
            url: '/huarenApi/pc/product/doFav',
            method: 'post',
            data
        })
    },
    //留言
    saveMessage(data) {
        return _axios({
            url: '/huarenApi/pc/product/proposal',
            method: 'post',
            data
        })
    },
    //推荐列表
    recommendList(data) {
        return _axios({
            url: '/huarenApi/pc/detail/recommend',
            method: 'post',
            data
        })
    },
    ///加入购物车
    addCart(data) {
        return _axios({
            url: '/huarenApi/pc/joinCar',
            method: 'post',
            data
        })
    },
    //获取购物车
    getCart(data) {
        return _axios({
            url: '/huarenApi/pc/returnCar',
            method: 'post',
            data
        })
    },
    //删除
    deleteCart(data) {
        return _axios({
            url: '/huarenApi/pc/deleteCar',
            method: 'post',
            data
        })
    },
    //选中
    selectCart(data) {
        return _axios({
            url: '/huarenApi/pc/checkedCar',
            method: 'post',
            data
        })
    },
    //数量
    changeNum(data) {
        return _axios({
            url: '/huarenApi/pc/editCar',
            method: 'post',
            data
        })
    },
    //收货地址
    getAddress(data) {
        return _axios({
            url: '/huarenApi/pc/buyer/address',
            method: 'post',
            data
        })
    },
    //订单信息
    getOrder(data) {
        return _axios({
            url: '/huarenApi/pc/submitCart',
            method: 'post',
            data
        })
    },
    //发票
    getInvoice(data) {
        return _axios({
            url: '/huarenApi/pc/invoiceOption',
            method: 'post',
            data
        })
    },
    //提交订单
    submitOrder(data) {
        return _axios({
            url: '/huarenApi/pc/submitOrder',
            method: 'post',
            data
        })
    },
    //支付方式
    getPayOption(data, responseType = "") {
        return _axios({
            url: '/huarenApi/pc/pay/payment',
            method: 'post',
            data,
            responseType
        })
    },
    //支付结果
    getPayResult(data) {
        return _axios({
            url: '/huarenApi/pc/pay/query',
            method: 'post',
            data
        })
    },
    //订单支付详情
    getPayDetail(data) {
        return _axios({
            url: '/huarenApi/pc/order/detail',
            method: 'post',
            data
        })
    },
    //下载首营资料
    downloadLicense(data) {
        return _axios({
            url: '/huarenApi/pc/product/license',
            method: 'post',
            data,
            responseType: 'blob'
        })
    },
    //法人购物列表
    multipleCartList(data) {
        return _axios({
            url: '/huarenApi/pc/returnCarV413',
            method: 'post',
            data
        })
    },
    //法人购物列表选中
    multipleCartSelect(data) {
        return _axios({
            url: '/huarenApi/pc/checkedCarV413',
            method: 'post',
            data
        })
    },
    //法人提交订单详情
    multipleSubOrder(data) {
        return _axios({
            url: '/huarenApi/pc/multipSubmitCart',
            method: 'post',
            data
        })
    },
    //法人选择地址
    multipleAddress(data) {
        return _axios({
            url: '/huarenApi/pc/multipMemberAddress',
            method: 'post',
            data
        })
    },
    //法人提交订单
    multipleSub(data) {
        return _axios({
            url: '/huarenApi/pc/multipSubmitOrder',
            method: 'post',
            data
        })
    },
    //法人支付详情
    multiplePay(data) {
        return _axios({
            url: '/huarenApi/pc/order/payData',
            method: 'post',
            data
        })
    },
    //法人支付结果
    multiplePayResult(data) {
        return _axios({
            url: '/huarenApi/pc/pay/multipleQuery',
            method: 'post',
            data
        })
    },
    //法人支付
    multipleDoPay(data,responseType) {
        return _axios({
            url: '/huarenApi/pc/pay/multiplePayment',
            method: 'post',
            data,
            responseType
        })
    },
    //法人对公支付
    multiplePayImg(data) {
        return _axios({
            url: '/huarenApi/pc/uploadLicense',
            method: 'post',
            data
        })
    },
    //赠品选择
    giveSelect(data) {
        return _axios({
            url: '/huarenApi/pc/setCartAct',
            method: 'post',
            data
        })
    },
    //商品详情检验
    goodsCheck(data) {
        return _axios({
            url: '/huarenApi/pc/product/checkPriceForBargain',
            method: 'post',
            data
        })
    },
    //商品详情检验选择
    selectCheck(data) {
        return _axios({
            url: '/huarenApi/pc/product/submitOptionForBargain',
            method: 'post',
            data
        })
    },
}