<template>
  <div id="app" name="app">
    <router-view />
    <loading-component></loading-component>
  </div>
</template>

<script>

import LoadingComponent from '@/components/LoadingComponent.vue'
export default {
  name: 'App',
  components: {
    LoadingComponent
  },
  data() {
    return {
      
    };
  },
  created() {

  },
  methods: {
    
  }
};
</script>

<style>
@import "../src/assets/css/index.scss";
</style>