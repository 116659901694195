'use strict'

import axios from 'axios'
import router from '../router'
import store from '../store'
import { Message } from 'element-ui'
import { exportResponseData } from '@/utils/common'
const config = {
  // baseURL: 'http://b2bmall.huaren.com:299',
  // baseURL: 'http://b2bmall.fastjz.com:299',//测试环境
  // baseURL: 'https://n48z043334.imdo.co/',//张传祥
  baseURL: 'https://b2bmall.hrjkjt.com',//正式
  timeout: 90 * 1000, // 超时时长
  withCredentials: true // 表示跨域请求时是否需要使用凭证
}




const _axios = axios.create(config)

// 请求拦截器
_axios.interceptors.request.use(
  function (config) {



// if (store.state.token) {
//       config.headers.token = store.state.token
//     } else if (window.localStorage.getItem('huaren-token')) {
//       config.headers.token = window.localStorage.getItem('huaren-token')
//       store.commit('setStoreToken', window.localStorage.getItem('huaren-token'))
//     }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// 响应拦截器
_axios.interceptors.response.use(
  function (response) {
    // if (response.data.code !== 200 && response.headers['content-type'] !== 'application/octet-stream;charset=UTF-8') {
    //   message.error(response.data.message)
    // }
    // token 过期
 
    console.log(response.data.code,'response.data');
   if(response.data.code){
    if (response.data.code !== 200) {
      if (document.getElementsByClassName('el-message').length === 0) {
        Message({
          message: response.data.msg||response.data.errMsg,
          type: "warning"
      })
			}
   
   }
     
    
    }
    if(response.data.code==200002){
      router.push('/login')
    }
    if (response.config.isExportExcel) {
      const data = response.data
      console.log(data,'data');
      const contentType = 'application/vnd.ms-excel'
      let fileName
      fileName = JSON.parse(response.config.data);
      exportResponseData(data, contentType, fileName.orderNumber)
      return
    }
    return response
  },
  function (error) {
    // if (error.response.request.responseText) {
    //   message.error(error.response.request.responseText)
    // }
    console.log(error,'error');
    if (error.response && error.response.status === 500) {
      message.error('网络错误！')
    } else if (error.response && error.response.request.responseText) {
      message.error(error.response.request.responseText)
    }
    // if (error.response.data.error) {
    // message.error(error.response.data.error)
    // }
    // Do something with response error
    return Promise.reject(error)
  }
)
export const exportExcel = config => {
  return _axios({
    method: 'post',
    isExportExcel: true,
    responseType: 'blob',
    ...config
  })
}
export default _axios
