<template>
  <transition name="toast-fade">
    <div v-if="isVisible" class="toast-container">
      {{ message }}
    </div>
  </transition>
</template>
 
<script>
export default {
  data() {
    return {
      message: '',
      isVisible: false,
    };
  },
  methods: {
    show(message) {
      this.message = message;
      this.isVisible = true;
      setTimeout(() => {
        this.isVisible = false;
      }, 3000); // 默认显示3秒
    }
  }
};
</script>
 
<style scoped>
.toast-fade-enter-active, .toast-fade-leave-active {
  transition: opacity 0.5s;
}
.toast-fade-enter, .toast-fade-leave-to /* .fade-leave-active for <2.1.8 */ {
  opacity: 0;
}
 
.toast-container {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2010;
  transform: translate(-50%,-50%);
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 68px 68px 68px 68px;
  font-size: 14px;
  color: #FFFFFF;
}
</style>