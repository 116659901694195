// 格式化商品价格样式
function initPrice(price, n) {
	if(!price) price = 0
	n = n || 2
	var reg = Number(price).toFixed(n).match(/\.(\S*)/)[0];
	var replace = `<span>${reg}</span>`;
	if (price) {
		price = Number(price)
		let str = '<span style="font-size: 12px;">￥</span>' + price.toFixed(n).replace(reg, replace)
		return str
	} else {
		var zero = 0
		let str = '<span style="font-size: 12px;">￥</span>' + zero.toFixed(n).replace(reg, replace)
		return str
	}
}
export default {
    initPrice
}